import(/* webpackMode: "eager" */ "/codebuild/output/src4204743316/src/VillaStayApp/apps/client/src/app/(home)/landing.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/BookOnline.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/BroadcastOnPersonal.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/CloudSync.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/DeviceHub.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/Edit.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/EmojiEvents.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/Insights.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src4204743316/src/VillaStayApp/node_modules/@mui/icons-material/MobileFriendly.js");
